import React from "react"
import Layout from "../components/layout"
import content from "../../content/use-examples.yaml"

const About = () => {
  return <Layout>
    <section className="details" id="mainTitle" style={{ background: "white", color: "black" }}>
      <div className="row">
        <div className="twelve columns">
          <br/>
          <h1 className="responsive-headline" style={{ color: "black" }}>{content.headline}</h1>
          <br/>
          {
            content.sections.map((section, index) => <React.Fragment key={index}>
              <div id={`${section.anchorTag}`}  style={{position: 'relative', top: '-60px'}}></div>
              {
                section.headline && <div>
                  <h1 className="responsive-headline" style={{ color: "black" }}>{section.headline}</h1>
                  <br/>
                </div>
              }
              {
                section.headline2 && <div>
                  <h2 className="responsive-headline" style={{ color: "black" }}>{section.headline2}</h2>
                  <br/>
                </div>
              }
              {
                section.headline3 && <div>
                  <h2 className="responsive-headline" style={{ color: "black" }}>{section.headline3}</h2>
                </div>
              }
              <div>
                <h3 className="responsive-headline" style={{ color: "black" }}>{section.title}</h3>
              </div>
              <div>{section?.paragraphs?.map(paragraph =>
              {
                if (paragraph.list) {
                  return <ol>
                    {paragraph.list.map(item => <li>{item}</li>)}
                  </ol>
                } else
                  return (<p>{paragraph}</p>)
              })
              }</div>

              <div className="buttons" style={{ textAlign: "left", paddingTop: "0px", marginBottom: "30px" }}
                   id="whatSection">
                <a className="detailsLink" id="mc-embedded-subscribe"
                   href={`mailto: ${section?.button?.to}`}>{section?.button?.label}</a>
              </div>
            </React.Fragment>)
          }
        </div>
      </div>
    </section>
  </Layout>
}

export default About